import React from 'react';

export default () => (
  <g>
    <path stroke="#323f47" strokeWidth="2" d="m0 3.5h20" />
    <path stroke="#323f47" strokeWidth="2" d="m0 11.5137h20" />
    <path
      stroke="#323f47"
      strokeWidth="2"
      fill="#fff"
      d="m8.84205 3.5c0 1.39062-1.1128 2.5-2.4635 2.5-1.35071 0-2.46351-1.10938-2.46351-2.5s1.1128-2.5 2.46351-2.5c1.3507 0 2.4635 1.10938 2.4635 2.5z"
    />
    <path
      stroke="#323f47"
      strokeWidth="2"
      fill="#fff"
      d="m16.0003 11.5137c0 1.3906-1.1128 2.5-2.4636 2.5-1.3507 0-2.4635-1.1094-2.4635-2.5s1.1128-2.50003 2.4635-2.50003c1.3508 0 2.4636 1.10943 2.4636 2.50003z"
    />
  </g>
);
