import React, { FunctionComponent } from 'react';

import profile from './icons/profile';
import bag from './icons/bag';
import arrowRight from './icons/arrow-right';
import trash from './icons/trash';
import ticket from './icons/ticket';
import closeLarge from './icons/close-large';
import plus from './icons/plus';
import info from './icons/info';
import infoBold from './icons/info-bold';
import delivery from './icons/delivery';
import pay from './icons/pay';
import rules from './icons/rules';
import control from './icons/control';
import docBold from './icons/doc-bold';
import tel from './icons/tel';
import letter from './icons/letter';
import telegram from './icons/telegram';
import viber from './icons/viber';
import wa from './icons/wa';
import instagram from './icons/instagram';
import instagramDot from './icons/instagram-dot';
import unsplash from './icons/unsplash';
import link from './icons/link';
import arrowDownFilled from './icons/arrow-down-filled';

type PT = {
  icon: string;
  size?: string | number;
  viewBox?: string;
  cssClassName?: string;
};

const Icon: FunctionComponent<PT> = ({ icon, size, viewBox, cssClassName }) => {
  const styles = {
    verticalAlign: 'middle',
    width: `${size}px`,
    height: `${size}px`,
  };

  function renderSvgContent(name: string) {
    switch (name) {
      case 'profile':
        return profile();
      case 'bag':
        return bag();
      case 'arrow-right':
        return arrowRight();
      case 'trash-bin':
        return trash();
      case 'ticket':
        return ticket();
      case 'close-large':
        return closeLarge();
      case 'plus':
        return plus();
      case 'info':
        return info();
      case 'info-bold':
        return infoBold();
      case 'delivery':
        return delivery();
      case 'pay':
        return pay();
      case 'rules':
        return rules();
      case 'control':
        return control();
      case 'doc-bold':
        return docBold();
      case 'tel':
        return tel();
      case 'letter':
        return letter();
      case 'telegram':
        return telegram();
      case 'viber':
        return viber();
      case 'wa':
        return wa();
      case 'instagram':
        return instagram();
      case 'instagramDot':
        return instagramDot();
      case 'unsplash':
        return unsplash();
      case 'link':
        return link();
      case 'arrow-down-filled':
        return arrowDownFilled();
      default:
        return <g />;
    }
  }

  return (
    <svg
      className={cssClassName}
      viewBox={viewBox || `0 0 ${size} ${size}`}
      fill="none"
      style={styles}
    >
      {renderSvgContent(icon)}
    </svg>
  );
};

export default Icon;
