import React from 'react';

export default () => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.5C0.447715 0.5 0 0.947715 0 1.5V2.34086L8.16171 7.32533L16 2.53839V1.5C16 0.947715 15.5523 0.5 15 0.5H1ZM16 4.88186L8.68292 9.3505L8.16171 9.66881L7.64051 9.3505L0 4.68434V10.5C0 11.0523 0.447715 11.5 1 11.5H15C15.5523 11.5 16 11.0523 16 10.5V4.88186Z"
      fill="#323F47"
    />
  </g>
);
