import React from 'react';

export default () => (
  <g>
    <path
      d="M17 7.52746H11.651H11.6316V12.254H5.34897V7.52746H0V17H17V7.52746Z"
      fill="#323F47"
    />
    <path d="M5.34897 0H11.6316V4.746H5.34897V0Z" fill="#323F47" />
  </g>
);
