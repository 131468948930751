import React from 'react';

export default () => (
  <g>
    <circle cx="10" cy="10.6792" r="9" stroke="#323F47" strokeWidth="2" />
    <circle cx="10" cy="9.63721" r="3" stroke="#323F47" strokeWidth="2" />
    <path
      d="M4.67188 18.0272C5.84725 16.3491 7.79523 15.252 9.99948 15.252C12.1144 15.252 13.9935 16.262 15.1805 17.8261"
      stroke="#323F47"
      strokeWidth="2"
    />
  </g>
);
