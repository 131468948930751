import React, { ReactNode, useCallback } from 'react';
import history from '../../history';

function isLeftClickEvent(event: MouseEvent) {
  return event.button === 0;
}

function isModifiedEvent(event: MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

type PT = {
  to: string;
  onClick?: Function;
  children?: ReactNode;
  className?: string;
  onMouseEnter?: Function;
};

const Link = ({ to, children, onClick, ...restProps }: PT) => {
  const handleClick = useCallback((event: any) => {
    if (onClick) onClick(event);
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) return;
    if (event.defaultPrevented === true) return;

    event.preventDefault();

    history.push(to);
  }, [to, onClick, history]);

  return (
    <a
      href={to}
      {...restProps}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export default Link;
