import React, { FC, useState, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Loading from 'components/Loading';
import { useIntersectionObserver } from 'utils';
import s from './Picture.css';

type Size = {
  jpg: string;
  webp: string;
};

type SizesType = {
  small?: Size;
  large?: Size;
  medium?: Size;
  default: any;
};

type PT = {
  sizes: SizesType;
  className?: string;
  alt?: string;
};

const Picture: FC<PT> = ({ sizes, alt = '', className, ...props }) => {
  useStyles(s);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
    rootMargin: '200px',
  });
  const isInView = !!entry?.isIntersecting;

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <figure
      ref={ref}
      className={cx(className, s.imageContainer)}
    >
      {!isLoaded && (
        <>
          <img src="/img/placeholder.svg" alt="" />
          <span className={s.loader}>
            <Loading />
          </span>
        </>
      )}
      {isInView && (
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={sizes?.small?.webp}
            type="image/webp"
          />
          <source
            media="(max-width: 1920px)"
            srcSet={sizes?.large?.webp}
            type="image/webp"
          />
          <source
            media="(max-width: 768px)"
            srcSet={sizes?.small?.jpg}
          />
          <source
            media="(max-width: 1920px)"
            srcSet={sizes?.large?.jpg}
          />
          <img
            src={sizes?.default?.jpg}
            className={cx(s.imageMain, { [s.isLoaded]: !!isLoaded })}
            alt={alt}
            {...props}
            onLoad={handleOnLoad}
            onError={() => setIsLoaded(false)}
          />
        </picture>
      )}
    </figure>
  );
};

export default Picture;
