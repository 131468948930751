import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Footer.css';

type PT = {
  theme?: string;
};

const Footer: FC<PT> = () => {
  useStyles(s);

  return (
    <footer className={s.root}>
      <div className={s.container}>
        <div className={s.section}>
          <div className={s.lists}>
            <ul>
              {/* <li>Для всех</li> */}
              {/* <li>
                <a href="/about">О нас</a>
              </li> */}
              {/* <li>
                <a href="/brands">Все магазины</a>
              </li> */}
              {/* <li>
                <a
                  href="https://www.instagram.com/nalune.market/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Мы в Instagram
                </a>
              </li> */}
            </ul>
            {/* <ul>
              <li>Продавцам</li>
              <li>
                <a href="/photo-requirements">Требования к фотографиям</a>
              </li>
            </ul> */}
            {/* <ul>
              <li>Покупателям</li>
              <li>
                <a href="/how-to-buy">Как покупать</a>
              </li>
            </ul> */}
          </div>
          {/* <div className={s.aside}></div> */}
        </div>
        <div className={s.section}>
          <div className={s.companyInfo} />
          <div className={s.payments}>
            <picture>
              <source
                media="(min-width:480px)"
                srcSet="/img/payments/all-in-one.png"
              />
              <img
                src="/img/payments/all-in-one.png"
                alt="Payment provider logos"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
