exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Roboto\",sans-serif;--letter-spacing:1px;--max-content-width:1180px;--page-content-padding-top:70px;--color-dark:#292b35;--color-gray:#7a8389;--color-gray-light:#aab4ba;--color-light:#eef2f4;--color-error:#cb1031;--color-blue-dark:#011eaa;--color-green:#0f8453;--color-olivo:#b0ae9c;--color-magazine-backgroud:#e5e5e5;--color-button-dark:#1d2327;--color-button-disabled:#7a8389;--color-input-border:#aab4ba;--color-input-focus-border:#7a8389;--screen-xs-max:480px;--screen-sm-max:768px;--screen-md-max:992px;--screen-lg-max:1200px}._1U2x2{position:relative}._1U2x2._2fCdS ._1XfaK{display:none}._1U2x2._2fCdS ._2XRh7{transform:rotate(0deg)}.lBXoj{display:flex;align-items:center;position:relative;height:90px;text-transform:uppercase;color:#292b35;color:var(--color-dark);font-size:14px;font-size:.875rem;font-weight:700;letter-spacing:1px;letter-spacing:var(--letter-spacing);border-bottom:1px solid #eef2f4;border-bottom:1px solid var(--color-light);cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._2XRh7{position:absolute;right:10px;top:50%;margin-top:-4px;transform:rotate(180deg)}._1XfaK{margin:0;padding:0;list-style:none}._1XfaK li{position:relative;height:70px;width:100%;font-size:16px;font-size:1rem;border-bottom:1px solid #eef2f4;border-bottom:1px solid var(--color-light)}._1XfaK li a{display:flex;align-items:center;position:absolute;left:0;top:0;right:10px;height:100%;color:#292b35;color:var(--color-dark);text-decoration:none}._3N1Tg{position:relative;width:40px;height:40px;margin-right:15px;border-radius:50%;background-color:#eef2f4;background-color:var(--color-light)}._3x1ky{margin-left:auto;color:#aab4ba;color:var(--color-gray-light)}", ""]);
// Exports
exports.locals = {
	"root": "_1U2x2",
	"collapsed": "_2fCdS",
	"list": "_1XfaK",
	"headerIcon": "_2XRh7",
	"header": "lBXoj",
	"image": "_3N1Tg",
	"count": "_3x1ky"
};