import React, { FC, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Header from '../Header';
import Footer from '../Footer';

interface PT {
  isTransparent?: boolean;
  disabledUI?: boolean;
  theme?: string;
  currentPage?: string;
}

const Layout: FC<PT> = ({
  children,
  isTransparent = false,
  disabledUI,
  theme,
  ...rest
}) => {
  useStyles(normalizeCss, s);

  useEffect(() => {
    // Ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

    // First we get the viewport height and we multiple it by 1%
    // to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh
    // custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  return (
    <div className={cx(s.layout, { [s.disabledUI]: disabledUI })}>
      {!disabledUI && (
        <Header isTransparent={isTransparent} theme={theme} {...rest} />
      )}
      {children}
      {!disabledUI && <Footer theme={theme} />}
    </div>
  );
};

export default Layout;
