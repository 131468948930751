exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2sj3i{position:relative;height:425px;width:100%;padding:0 10px}@media (min-width:992px){._2sj3i{height:500px}}._2sj3i>div{height:100%}._2GJJB{display:flex;flex-direction:column;position:relative;text-decoration:none;overflow:hidden}._2GJJB,._2GJJB img{width:100%;height:100%;border-radius:15px}._2GJJB img{display:block;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center}._2_xAR,._2GJJB img{position:absolute;left:0;top:0}._2_xAR{width:100%;height:100%;background:linear-gradient(180deg,transparent,rgba(0,0,0,.63) 53.12%,#000);opacity:.35;border-radius:15px}._1SesS{position:relative;margin:auto 30px 15px;font-size:12px;font-size:.75rem;color:#fff;text-transform:uppercase;font-weight:700;letter-spacing:var(--letter-spacing)}@media (min-width:768px){._1SesS{font-size:1rem}}._2-3Dj{position:relative;max-width:500px;margin:0 30px 90px;font-size:20px;font-size:1.25rem;color:#fff;text-transform:uppercase;font-weight:700;letter-spacing:var(--letter-spacing)}@media (min-width:768px){._2-3Dj{font-size:1.5rem}}._1WpA6{display:flex;justify-content:center;position:absolute;bottom:45px;left:0;width:100%;z-index:10}.JGbnx{display:block;width:25px;height:2px;margin-right:5px;background:var(--color-light);opacity:.3}.JGbnx:last-child{margin-right:0}._1daSJ{opacity:1}", ""]);
// Exports
exports.locals = {
	"root": "_2sj3i",
	"slide": "_2GJJB",
	"shadow": "_2_xAR",
	"slideCategory": "_1SesS",
	"slideTitle": "_2-3Dj",
	"pagination": "_1WpA6",
	"bullet": "JGbnx",
	"bulletActive": "_1daSJ"
};