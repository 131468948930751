import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash/get';
import Product from 'components/Product';
import Icon from 'components/Icon';
import addProductMutation from 'modules/product/mutations/add-product.mutation.graphql';
import brandProductsQuery from 'modules/product/queries/products-by-brand.query.graphql';
import { PRODUCT_STATUS } from 'modules/product/constants';
import history from '../../history';
// eslint-disable-next-line css-modules/no-unused-class
import s from './ProductList.css';

import { Product as ProductType } from '../../graphql-generated-types';

type PT = {
  title?: string;
  products: ProductType[];
  columns?: number;
  setProductLink?: Function;
  showAddProductButton?: boolean;
  brandId?: string;
};

const ProductList: FC<PT> = ({
  title,
  products,
  columns = 3,
  setProductLink,
  showAddProductButton,
  brandId,
}) => {
  useStyles(s);

  const [mutate] = useMutation(addProductMutation);

  const handleAddProductClick = async () => {
    if (!brandId) return;

    const { data } = await mutate({
      variables: {
        newProductData: { name: '', brandId },
      },
      update: (cache, { data: { createProduct } }) => {
        if (!createProduct) return;

        const dataFromCache: any = cache.readQuery({
          query: brandProductsQuery,
          variables: { brandId },
        });

        cache.writeQuery({
          query: brandProductsQuery,
          variables: { brandId },
          data: {
            items: dataFromCache.products.concat(createProduct),
          },
        });
      },
    });

    const productId = get(data, 'addProduct.id', null);

    if (productId) history.push(`/brand/product-edit/${productId}`);
  };

  const classname = `size-${columns}`;

  return (
    <div className={s.root}>
      {title && <div className={s.title}>{title}</div>}
      <div className={cx(s.list, s[classname])}>
        {products.map((product: any) => (
          <Product
            product={product}
            key={product.id}
            className={s.product}
            link={
              setProductLink ? setProductLink(product.id) : `/p/${product.id}`
            }
            hidden={product.status && product.status === PRODUCT_STATUS.HIDDEN}
          />
        ))}
        {showAddProductButton && (
          <div
            className={cx(s.addProductButton, s[classname])}
            onClick={handleAddProductClick}
          >
            <i />
            <i />
            <div>
              <Icon icon="plus" size="21" viewBox="0 0 21 20" />
              Добавить товар
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
