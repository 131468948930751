import React from 'react';

export default () => (
  <g>
    <path
      d="M20 10.5V20H10.5C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5Z"
      stroke="#323F47"
      strokeWidth="2"
    />
    <path d="M10.5 15.0757L10.5 9.07568" stroke="#323F47" strokeWidth="2" />
    <path d="M10.5 8.07568L10.5 6.07568" stroke="#323F47" strokeWidth="2" />
  </g>
);
