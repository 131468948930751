import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Link from 'components/Link';
import Picture from 'components/Picture';
import { getSignedResizedUrl } from 'utils';
import s from './Product.css';

import { Product as ProductType } from '../../graphql-generated-types';

type PT = {
  product: ProductType;
  className?: string;
  link: string;
  hidden: boolean;
};

const Product: FC<PT> = ({ product, className, link, hidden = false }) => {
  useStyles(s);

  const {
    name,
    imageDefault,
    brand,
    price,
    priceWithDiscount,
    currency,
  } = product;

  const sizes = {
    large: {
      width: '280',
      height: '350',
    },
    small: {
      width: '196',
      height: '245',
    },
  };

  return (
    <div className={cx(s.root, className, { [s.hidden]: hidden, [s.discount]: priceWithDiscount && priceWithDiscount > 0 })}>
      <Link to={link} rel="prefetch">
        <Picture
          sizes={{
            large: {
              jpg:
                imageDefault && imageDefault.fallbackUrl
                  ? getSignedResizedUrl({
                      url: imageDefault.fallbackUrl,
                      width: sizes.large.width,
                      height: sizes.large.height,
                      extension: 'jpg',
                    })
                  : '/img/placeholder.svg',
              webp:
                imageDefault && imageDefault.fallbackUrl
                  ? getSignedResizedUrl({
                      url: imageDefault.fallbackUrl,
                      width: sizes.large.width,
                      height: sizes.large.height,
                      extension: 'webp',
                    })
                  : '',
            },
            small: {
              jpg:
                imageDefault && imageDefault.fallbackUrl
                  ? getSignedResizedUrl({
                      url: imageDefault.fallbackUrl,
                      width: sizes.small.width,
                      height: sizes.small.height,
                      extension: 'jpg',
                    })
                  : '/img/placeholder.svg',
              webp:
                imageDefault && imageDefault.fallbackUrl
                  ? getSignedResizedUrl({
                      url: imageDefault.fallbackUrl,
                      width: sizes.small.width,
                      height: sizes.small.height,
                      extension: 'webp',
                    })
                  : '',
            },
            default: {
              jpg:
                imageDefault && imageDefault.fallbackUrl
                  ? getSignedResizedUrl({
                      url: imageDefault.fallbackUrl,
                      width: sizes.large.width,
                      height: sizes.large.height,
                      extension: 'jpg',
                    })
                  : '/img/placeholder.svg',
            },
          }}
          className={s.image}
          alt=""
          // width={width}
          // height={height}
        />
        <div className={s.name}>{name}</div>
      </Link>
      {brand ? (
        <a href={`/b/${brand.slug}`} className={s.brandName}>
          {brand.name}
        </a>
      ) : null}
      {price && (
        <div className={s.price}>
          {priceWithDiscount && priceWithDiscount !== 0 ? (
            <>
              <strong>
                {priceWithDiscount} {currency}
              </strong>
              <span className={s.priceWithDiscount}>
                {price} {currency}
              </span>
            </>
          ) : (
            <strong>
              {price} {currency}
            </strong>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
