import React, { FC} from 'react';
import { useQuery } from '@apollo/client';
import useStyles from 'isomorphic-style-loader/useStyles';
import CatalogItem from 'components/CatalogItem';
import categoryTreeQuery from '../Menu/category-tree.query.graphql';
import { CategoryTreeItem } from '../../graphql-generated-types';
import s from './CatalogSection.css';

type MenuData = {
  categoryTree: CategoryTreeItem[];
};

const CatalogSection: FC<{}> = () => {
  useStyles(s);

  const {
    loading,
    data: { categoryTree } = { categoryTree: [] } 
  } = useQuery<MenuData>(
    categoryTreeQuery,
    { fetchPolicy: 'cache-and-network' },
  );

  return (
    <div className={s.root}>
      {!loading && (categoryTree[0]?.children ?? []).map(({ id, name, slug, count, children }) => (
        <CatalogItem
          title={name ?? ''}
          list={children as any[]}
          count={count as number}
          parentCategoryLink={`/c/${slug}`}
          key={id}
        />
      ))}
    </div>
  );
};

export default CatalogSection;