import emailValidateFormat from './emailValidateFormat';

export default function(values: any) {
  const errors: any = {};

  const isNotUndefined = (value: any) => typeof value !== 'undefined';
  const isStringEmpty = (value: string) => value.trim() === '';
  const isFieldEmpty = (value: any) => isNotUndefined(value) && isStringEmpty(value);

  // email
  if (isFieldEmpty(values.email)) {
    errors.email = 'Заполните поле';
  } else if (emailValidateFormat(values.email)) {
    errors.email = 'Неверный формат';
  }

  // password
  if (isFieldEmpty(values.password)) errors.password = 'Заполните поле';

  // password confirm check
  if (
    isNotUndefined(values.password) &&
    isNotUndefined(values.passwordConfirm) &&
    values.password !== values.passwordConfirm
  )
    errors.passwordConfirm = 'Пароли не совпадают';

  // password confirm
  if (isFieldEmpty(values.passwordConfirm)) errors.passwordConfirm = 'Заполните поле';

  // firstname
  if (isFieldEmpty(values.firstname)) errors.firstname = 'Заполните поле';

  // lastname
  if (isFieldEmpty(values.lastname)) errors.lastname = 'Заполните поле';

  // phone
  if (isFieldEmpty(values.phone)) errors.phone = 'Заполните поле';

  // delivery
  if (isFieldEmpty(values.delivery)) errors.delivery = 'Выберите способ доставки';

  // payment
  if (isFieldEmpty(values.payment)) errors.payment = 'Выберите способ оплаты';

  // country
  if (isFieldEmpty(values.country)) errors.country = 'Заполните страну';

  // city
  if (isFieldEmpty(values.city)) errors.city = 'Заполните ваш город';

  // region
  if (isFieldEmpty(values.region)) errors.region = 'Заполните область';

  // address
  if (isFieldEmpty(values.address)) errors.address = 'Заполните ваш адрес';

  // postIndex
  if (isFieldEmpty(values.postIndex)) errors.postIndex = 'Введите ваш почтовый индекс';

  return errors;
}
