import React from 'react';

export default () => (
  <g>
    <path
      d="M1.18978 22.4755L1.18978 4.00635C1.18978 2.3495 2.53293 1.00635 4.18979 1.00635L14.8115 1.00635C16.4684 1.00635 17.8115 2.3495 17.8115 4.00635L17.8115 22.4755L16.8785 22.0747L16.4838 21.9052L16.0891 22.0747L14.1561 22.9052L12.2231 22.0747L11.8284 21.9052L11.4336 22.0747L9.50065 22.9052L7.56766 22.0747L7.17294 21.9052L6.77821 22.0747L4.84522 22.9052L2.91222 22.0747L2.5175 21.9052L2.12278 22.0747L1.18978 22.4755Z"
      stroke="#AAB4BA"
      strokeWidth="2"
    />
    <path d="M4.98242 7.52625H14.001" stroke="#AAB4BA" strokeWidth="2" />
    <path d="M4.98242 11.9999H14.001" stroke="#AAB4BA" strokeWidth="2" />
    <path d="M4.98242 16.1403H9.50032" stroke="#AAB4BA" strokeWidth="2" />
  </g>
);
