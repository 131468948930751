import React, { FC, ReactNode } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import s from './Button.css';

type PT = {
  children?: ReactNode;
  className?: string;
  white?: boolean;
};

const Button: FC<PT> = ({ className, children, white, ...props }) => {
  useStyles(s);
  return (
    <button type="button" className={cx(s.root, { [s.white]: !!white }, className)} {...props}>
      {children}
    </button>
  );
};

export default Button;
