import React from 'react';
import { HomePage } from 'modules/home/components';
import Layout from 'components/Layout';

const title = 'Маркет особенных товаров';

async function action({ client }: RouteAction) {
  return {
    title,
    chunks: ['home'],
    component: (
      <Layout>
        <HomePage title={title} />
      </Layout>
    ),
  };
}

export default action;
