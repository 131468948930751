import React from 'react';

export default () => (
  <g>
    <path
      d="m4.47969 4.97744-3.42824-3.80916c-.405421-.45047-.085736-1.16828.5203-1.16828h6.8565c.60604 0 .92572.717811.5203 1.16828l-3.42824 3.80916c-.27807.30896-.76255.30896-1.04062 0z"
      fill="#292b35"
    />
  </g>
);
