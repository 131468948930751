import React, { FC, useMemo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useQuery } from '@apollo/client';
import shuffle from 'lodash/shuffle';
import ProductList from 'components/ProductList';
import Slider from 'components/Slider';
import Link from 'components/Link';
import Button from 'components/Button';
import CatalogSection from 'components/CatalogSection';
import Subscribe from 'components/Subscribe';
import s from './HomePage.css';
import productsQuery from './products-query.graphql';
import productsOnDiscountQuery from './products-on-discount.query.graphql';

type PT = {
  title?: string;
};

const HomePage: FC<PT> = ({ title }) => {
  useStyles(s);

  // get last added products
  const { data: recentProducts, loading: recentProductsLoading } = useQuery(
    productsQuery,
    { fetchPolicy: 'no-cache' },
  );

  // get products on discount
  const { data: discountProducts, loading: discountProductsLoading } = useQuery(
    productsOnDiscountQuery,
    { fetchPolicy: 'no-cache' },
  );

  const products = useMemo(() => {
    return !recentProductsLoading
      ? shuffle(recentProducts?.products?.items ?? [])?.slice(0, 16)
      : [];
  }, [recentProductsLoading, recentProducts]);

  const productsOnDiscount = useMemo(
    () => {
      return !discountProductsLoading && shuffle(discountProducts?.products?.items ?? [])?.slice(0, 8);
    },
    [discountProductsLoading, discountProducts]
  );

  return (
    <div className={s.root}>
      <img
        src="/img/title.svg"
        alt="Маркет особенных товаров"
        className={s.titleImg}
      />
      <div className={s.slider}>
        <Slider />
      </div>

      <div className={s.catalog}>
        <CatalogSection />
      </div>

      <section className={s.section}>
        <h2>Скидки</h2>
        {!discountProductsLoading && (
          <>
            <ProductList
              products={productsOnDiscount as any[]}
              columns={4}
            />
            <Link to="/s/sale" className={s.linkButton}>
              <Button white>Все скидки</Button>
            </Link>
          </>
        )}
      </section>
      <section className={s.section}>
        <h2>Товары</h2>
        {!recentProductsLoading && products?.length > 0 && (
          <>
            <ProductList
              products={products}
              columns={4}
            />
            <Link to="/c/catalog" className={s.linkButton}>
              <Button white>Перейти в каталог</Button>
            </Link>
          </>
        )}
      </section>

      {/* <section className={s.call}>
        <div className={s.callContent}>
          <h3>Открой магазин на Луне</h3>
          <p>Продавай всем, кто ценит оригинальность</p>
          <Link to="/placement">
            <Button white>Подробнее</Button>
          </Link>
        </div>
      </section> */}

      {/* <section className={s.subscription}>
        <div className={s.subscriptionContent}>
          <Subscribe />
        </div>
      </section> */}
    </div>
  );
};

export default HomePage;
