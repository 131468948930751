/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

type CookieOptions = {
  path?: string;
  domain?: string;
  expires?: Date;
  maxAge?: number;
  httpOnly?: boolean;
  secure?: boolean;
  sameSite?: boolean | 'lax' | 'strict' | 'none';
};

export default function setCookie(
  name: string,
  value: string | number,
  options?: CookieOptions,
): void {
  const date = new Date();

  // set it expire in 30 days by default
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);

  options = {
    path: '/',
    expires: date,
    // add other fields here
    ...options,
  };

  if (options.expires instanceof Date) {
    options = { ...options, expires: options.expires.toUTCString() };
  }

  if (typeof options.maxAge !== 'undefined') {
    options = { ...options, 'max-age': options.maxAge };
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) updatedCookie += `=${optionValue}`;
  }

  document.cookie = updatedCookie;
}
