/* eslint-disable css-modules/no-undef-class */
import React, { useState, FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import cx from 'classnames';
import Headroom from 'react-headroom';
import Link from 'components/Link';
// import Menu from 'components/Menu';
// import SubMenu from 'components/SubMenu';
import CartIcon from 'components/CartIcon';
import Icon from 'components/Icon';
// import MobileMenu from 'components/MobileMenu';
import s from './Header.css';
import cartItemsQuery from './cart-products.query.client.graphql';
import categoryTreeQuery from '../Menu/category-tree.query.graphql';
import { CategoryTreeItem } from '../../graphql-generated-types';

type MenuData = {
  categoryTree: CategoryTreeItem[];
};

type PT = {
  isTransparent?: boolean;
  currentPage?: string;
  theme?: string;
};

const Header: FC<PT> = ({ isTransparent, currentPage }) => {
  useStyles(s);

  const [isPinned, setPinned] = useState<boolean>(false);
  const [isFixed, setFixed] = useState<boolean>(false);
  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);
  const [activeMenuIndex, setActiveMenu] = useState<number>(0);

  // load categories
  const { loading, data: { categoryTree } = { categoryTree: [] } } = useQuery<
    MenuData
  >(categoryTreeQuery, {
    fetchPolicy: 'network-only',
  });

  // get cart items
  const { data } = useQuery(cartItemsQuery);
  const items = get(data, 'cart.items', []);
  const itemsInCart = items.length;

  return (
    <Headroom
      onUnfix={() => {
        setPinned(false);
        setFixed(false);
      }}
      onPin={() => {
        setFixed(true);
        setPinned(true);
      }}
      onUnpin={() => {
        setMenuVisibility(false);
      }}
      style={{ zIndex: 100 }}
    >
      <header
        className={cx(s.root, {
          [s.hasWhiteBackground]: isMenuVisible,
          [s.isTransparent]: isTransparent,
          [s.pinned]: isPinned,
          [s.fixed]: isFixed,
        })}
        onMouseLeave={() => setMenuVisibility(false)}
        onClick={() => setMenuVisibility(false)}
      >
        <div className={s.container}>
          {/* {!loading && (
            <>
              <MobileMenu
                itemsInCart={itemsInCart}
                categoryTree={categoryTree}
                activeMenuIndex={activeMenuIndex}
                setActiveMenu={setActiveMenu}
              />
              <Menu
                isTransparent={isTransparent && !isMenuVisible}
                setMenuVisibility={setMenuVisibility}
                setActiveMenu={setActiveMenu}
                activeMenuIndex={activeMenuIndex}
                isMenuVisible={isMenuVisible}
                loading={loading}
                categoryTree={categoryTree}
                currentPage={currentPage}
              />
              {isMenuVisible && <SubMenu data={categoryTree[activeMenuIndex].children} />}
            </>
          )} */}

          <Link className={s.logo} to="#"/>

          {!loading && (
            <div className={s.aside}>
              <Link to="/cart" className={s.cartLink}>
                <CartIcon total={itemsInCart} className={s.cartIcon} />
              </Link>
              <Link to="/profile" className={s.profileLink}>
                <Icon
                  icon="profile"
                  viewBox="0 0 20 21"
                  size="20"
                  cssClassName={s.profileIcon}
                />
              </Link>
            </div>
          )}
        </div>
      </header>
    </Headroom>
  );
};

export default Header;
