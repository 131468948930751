import React, { FunctionComponent, ReactNode } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Icon from 'components/Icon';
import s from './CartIcon.css';

type PT = {
  total: number;
  className: string;
};

const CartIcon: FunctionComponent<PT> = ({
  total,
  className,
  ...props
}) => {
  useStyles(s);
  return (
    <button type="button" className={cx(s.root, className)} {...props}>
      <Icon icon="bag" viewBox="0 0 18 20" size="20" />
      {total > 0 && <span className={s.counter}>{total}</span>}
    </button>
  );
};

export default CartIcon;
