import React from 'react';

export default () => (
  <g>
    <path d="M15.6549 2.70896C15.2054 2.29575 13.3858 0.972727 9.32974 0.954603C9.32974 0.954603 4.54874 0.664627 2.21805 2.80321C0.920408 4.10085 0.463694 5.99658 0.416573 8.35264C0.369452 10.7051 0.307831 15.1164 4.55962 16.3125H4.56324L4.55962 18.1357C4.55962 18.1357 4.53425 18.8752 5.01996 19.0238C5.60716 19.205 5.95513 18.6432 6.51696 18.0379C6.82506 17.7044 7.24915 17.2187 7.57175 16.8453C10.4788 17.0882 12.7152 16.53 12.9689 16.4466C13.5562 16.2545 16.88 15.8304 17.4201 11.4191C17.9783 6.88101 17.1482 4.00661 15.6549 2.70896ZM16.1478 11.1002C15.6911 14.7829 12.9979 15.0149 12.5014 15.1743C12.2911 15.2432 10.3265 15.7326 7.85448 15.5694C7.85448 15.5694 6.01313 17.7914 5.44042 18.3677C5.3498 18.4583 5.24469 18.4946 5.17582 18.4765C5.07795 18.4511 5.04895 18.3351 5.05258 18.1647C5.05258 17.9183 5.06708 15.1308 5.06708 15.1308C5.06345 15.1308 5.06345 15.1308 5.06708 15.1308C1.46774 14.1341 1.67797 10.3789 1.71784 8.41426C1.75772 6.44967 2.12744 4.83667 3.22572 3.75288C5.19394 1.96952 9.25724 2.23413 9.25724 2.23413C12.6862 2.24863 14.3282 3.28167 14.7088 3.62602C15.9738 4.71343 16.619 7.3051 16.1478 11.1002Z" fill="#323F47"/>
    <path d="M11.0002 8.48296C10.8806 8.48296 10.7791 8.38872 10.7719 8.26548C10.732 7.48616 10.3659 7.10557 9.61923 7.0657C9.49236 7.05845 9.3945 6.94971 9.40175 6.82284C9.40899 6.69598 9.51774 6.59811 9.6446 6.60536C10.6305 6.65973 11.1815 7.22518 11.2322 8.2401C11.2395 8.36697 11.1416 8.47571 11.0147 8.47934C11.0075 8.48296 11.0039 8.48296 11.0002 8.48296Z" fill="#323F47"/>
    <path d="M12.1754 8.87468H12.1718C12.045 8.87105 11.9435 8.76594 11.9471 8.63907C11.9652 7.85613 11.7405 7.22181 11.262 6.69985C10.7872 6.17789 10.1311 5.89516 9.2648 5.83354C9.13793 5.82267 9.04369 5.71393 9.05456 5.58706C9.06544 5.4602 9.17418 5.36596 9.30105 5.37683C10.2797 5.44932 11.0518 5.79005 11.6027 6.39175C12.1573 6.99708 12.4255 7.75464 12.4074 8.64994C12.4038 8.77681 12.2987 8.87468 12.1754 8.87468Z" fill="#323F47"/>
    <path d="M13.3787 9.34539C13.2518 9.34539 13.1503 9.2439 13.1503 9.11703C13.1395 7.71427 12.7371 6.64498 11.9179 5.84392C11.1096 5.05373 10.0838 4.65139 8.87679 4.64051C8.74993 4.64051 8.64844 4.5354 8.64844 4.40853C8.64844 4.28167 8.75355 4.18018 8.87679 4.18018C10.2071 4.19105 11.338 4.63689 12.2333 5.51407C13.1322 6.39125 13.5925 7.6019 13.607 9.10978C13.6107 9.24027 13.5092 9.34539 13.3787 9.34539C13.3823 9.34539 13.3823 9.34539 13.3787 9.34539Z" fill="#323F47"/>
    <path d="M9.67462 11.3213C9.67462 11.3213 9.99722 11.3503 10.1712 11.1364L10.5083 10.7123C10.6714 10.5021 11.0665 10.368 11.4507 10.5818C11.6646 10.7014 12.0524 10.9407 12.2917 11.1183C12.549 11.3068 13.071 11.7417 13.0746 11.7454C13.3247 11.9556 13.3827 12.2637 13.2123 12.5935C13.2123 12.5935 13.2123 12.5972 13.2123 12.6008C13.0384 12.9089 12.8027 13.1989 12.5091 13.4671C12.5055 13.4671 12.5055 13.4707 12.5019 13.4707C12.259 13.6737 12.0198 13.7897 11.7878 13.8151C11.7588 13.8223 11.7262 13.8223 11.6827 13.8223C11.5812 13.8223 11.4797 13.8078 11.3782 13.7752L11.371 13.7643C11.0085 13.6628 10.4068 13.4091 9.40639 12.8545C8.75394 12.4957 8.21386 12.1296 7.75352 11.7635C7.51067 11.5714 7.26419 11.3575 7.01046 11.1038C7.00321 11.0965 6.99233 11.0857 6.98508 11.0784C6.97783 11.0712 6.96696 11.0603 6.95971 11.053C6.95246 11.0458 6.94159 11.0349 6.93434 11.0277C6.92709 11.0204 6.91621 11.0095 6.90896 11.0023C6.65886 10.7486 6.44138 10.5021 6.24927 10.2592C5.88317 9.80252 5.51707 9.25881 5.15823 8.60636C4.60365 7.60232 4.34992 7.00062 4.24843 6.64177L4.23755 6.63452C4.20493 6.53303 4.19043 6.43154 4.19043 6.33004C4.19043 6.28655 4.19043 6.25393 4.19768 6.22493C4.22668 5.98932 4.34267 5.75372 4.54203 5.51086C4.54203 5.50723 4.54565 5.50723 4.54565 5.50361C4.81388 5.20638 5.10386 4.9744 5.41196 4.80042C5.41196 4.80042 5.41558 4.80042 5.41921 4.80042C5.74543 4.63006 6.05353 4.68805 6.26739 4.93816C6.26739 4.93816 6.70598 5.46374 6.89446 5.72109C7.07208 5.96395 7.31131 6.34817 7.43092 6.56203C7.64478 6.94625 7.51067 7.34134 7.30043 7.50445L6.87634 7.84155C6.66248 8.01554 6.69148 8.33813 6.69148 8.33813C6.69148 8.33813 7.31856 10.7232 9.67462 11.3213Z" fill="#323F47"/>
  </g>
);
