import React from 'react';

export default () => (
  <g>
    <path
      d="M1.37988 9.24072L5.5002 5.12041L1.37988 1.00009"
      stroke="#2D3941"
      strokeWidth="2"
    />
  </g>
);
