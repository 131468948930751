import React from 'react';

export default () => (
  <g>
    <path
      d="M3 6.11389H17.9235V18.3695C17.9235 20.0264 16.5804 21.3695 14.9235 21.3695H6C4.34315 21.3695 3 20.0264 3 18.3695V6.11389Z"
      stroke="#323F47"
      strokeWidth="2"
    />
    <path d="M0.0410156 3.00562H21.0093" stroke="#323F47" strokeWidth="2" />
    <path d="M9.02539 1.10205H12.0254" stroke="#323F47" strokeWidth="2" />
  </g>
);
