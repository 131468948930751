import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Link from 'components/Link';
import Icon from 'components/Icon';
import s from './CatalogItem.css';

type PT = {
  title: string;
  list: Record<string, any>[];
  parentCategoryLink: string;
  count?: number;
};

const CatalogItem: FC<PT> = ({ title, list = [], parentCategoryLink = '', count }) => {
  useStyles(s);

  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <section className={cx(s.root, { [s.collapsed]: collapsed })}>
      <header
        className={s.header}
        onClick={() => setCollapsed(!collapsed)}
      >
        {title}
        <Icon icon="arrow-down-filled" viewBox="0 0 10 6" size="10" cssClassName={s.headerIcon} />
      </header>
      <ul className={s.list}>
        <li>
          <Link to={parentCategoryLink}>
            Все товары {count && <span className={s.count}>{count}</span>}
          </Link>
        </li>
        {list.map(({ name, slug, count }: Record<string, any>) => (
          <li key={slug}>
            <Link to={`/c/${slug}`}>
              {/* {image && <div className={s.image} />}  */}
              {name} <span className={s.count}>{count}</span>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default CatalogItem;