/* eslint-disable consistent-return */

type Category = {
  id: string;
  name: string;
  children: [];
};

const treeSearch = (tree: [], target: string): undefined | Category => {
  if (!Array.isArray(tree)) return undefined;

  const stack = [...tree];

  while (stack.length) {
    const curr = stack.pop();

    if (curr.id === target) return curr;

    if (curr.children) stack.push(...curr.children);
  }
};

export default treeSearch;
