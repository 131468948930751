import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Link from 'components/Link';
import swiperStyles from 'swiper/swiper.min.css';
import s from './Slider.css';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

type PT = {};

const Slider: FC<PT> = () => {
  useStyles(swiperStyles);
  useStyles(s);

  return (
    <div className={s.root}>
      <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickableClass: s.pagination,
            bulletClass: s.bullet,
            bulletActiveClass: s.bulletActive,
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            // pauseOnMouseEnter: true,
          }}
          draggable
          loop
        >
          <SwiperSlide>
            <Link className={s.slide} to="/s/dizajnerskie-novogodnie-podarki">
              <img src="/img/sets/newyear.jpg" alt="" />
              <div className={s.shadow} />
              <p className={s.slideCategory}>Каталог</p>
              <p className={s.slideTitle}>Особенные подарки для родных и близких на новогодние праздники</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link className={s.slide} to="/s/dizajnerskie-teplye-veshchi-na-zimu">
              <img src="/img/sets/outwear-wide.jpg" alt="" />
              <div className={s.shadow} />
              <p className={s.slideCategory}>Каталог</p>
              <p className={s.slideTitle}>Дизайнерские теплые вещи на зиму</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link className={s.slide} to="/c/for-house">
              <img src="/img/sets/home.jpg" alt="" />
              <div className={s.shadow} />
              <p className={s.slideCategory}>Каталог</p>
              <p className={s.slideTitle}>Товары для дома</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link className={s.slide} to="/magazine/article/photographers">
              <img src="/img/magazine/photographers/cover.jpg" alt="" />
              <div className={s.shadow} />
              <p className={s.slideCategory}>Журнал</p>
              <p className={s.slideTitle}>Предметные фотографы Беларуси</p>
            </Link>
          </SwiperSlide>
        </Swiper>
    </div>
  );
};

export default Slider;