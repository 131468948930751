import React from 'react';

export default () => (
  <g>
    <path
      d="M1.69922 6.42358H16.3005V15.6792C16.3005 17.3361 14.9574 18.6792 13.3005 18.6792H4.69922C3.04237 18.6792 1.69922 17.3361 1.69922 15.6792V6.42358Z"
      stroke="#323F47"
      strokeWidth="2"
    />
    <path
      d="M13 6.42358L13 5.42358C13 3.21445 11.2091 1.42358 9 1.42358V1.42358C6.79086 1.42358 5 3.21444 5 5.42358L5 6.42358"
      stroke="#323F47"
      strokeWidth="2"
    />
  </g>
);
