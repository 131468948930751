import React from 'react';

export default () => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38705 6.55291C9.73859 6.94259 9.62713 7.38406 9.3163 7.80294C9.2426 7.90225 9.15766 8.00011 9.05506 8.10793C9.00582 8.15969 8.96742 8.19861 8.88711 8.27901C8.7047 8.46159 8.55136 8.61501 8.42709 8.73928C8.36682 8.79953 8.77828 9.62173 9.57679 10.421C10.3749 11.2199 11.197 11.6316 11.2576 11.5711L11.7173 11.1111C11.9704 10.8577 12.1045 10.7354 12.2887 10.6146C12.6717 10.3633 13.0894 10.295 13.4417 10.6101C14.592 11.4329 15.243 11.9378 15.5619 12.2692C16.1839 12.9155 16.1024 13.9104 15.5654 14.4779C15.3792 14.6747 15.1431 14.9109 14.8643 15.1798C13.1775 16.8674 9.6177 15.8404 6.88947 13.1095C4.16062 10.3779 3.13411 6.81776 4.81733 5.13364C5.11951 4.8267 5.21915 4.72712 5.51345 4.43713C6.06139 3.89723 7.10215 3.81269 7.73482 4.43766C8.0676 4.7664 8.59816 5.44904 9.38705 6.55291Z"
      fill="#323F47"
    />
  </g>
);
