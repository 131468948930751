import React from 'react';

export default () => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#323F47"
      d="M4.31649 4.77652C5.49742 4.77652 6.45475 3.81919 6.45475 2.63826C6.45475 1.45733 5.49742 0.5 4.31649 0.5C3.13556 0.5 2.17823 1.45733 2.17823 2.63826C2.17823 3.81919 3.13556 4.77652 4.31649 4.77652ZM0.183166 7.76026L0.409658 7.61027L0.747146 7.41977C0.988638 7.30128 1.20163 7.20978 1.36962 7.14228C1.64659 7.0302 1.9301 6.93503 2.2186 6.85729C2.36859 6.8168 2.53059 6.7793 2.70908 6.7463C2.98578 6.69786 3.26538 6.6678 3.54605 6.6563C3.77305 6.65446 3.99952 6.67862 4.22103 6.7283C5.034 6.92479 5.44049 7.64777 5.22749 8.52824C5.0155 9.41422 4.79683 10.2989 4.57817 11.1835C4.46885 11.6258 4.35952 12.0681 4.25103 12.5106C4.09803 13.1346 4.26003 13.4151 4.8855 13.5846C4.9422 13.5999 5.00052 13.6119 5.05884 13.624C5.09772 13.632 5.1366 13.6401 5.17499 13.6491C5.50348 13.7241 5.58148 13.9296 5.37749 14.198C5.32422 14.2675 5.26045 14.3282 5.18849 14.378C4.15053 15.083 3.01507 15.5 1.75061 15.5C0.610651 15.5 -0.0118277 14.7125 0.250663 13.6131C0.455371 12.7552 0.665781 11.8993 0.876128 11.0436C0.989447 10.5827 1.10275 10.1218 1.21513 9.6607C1.21791 9.64909 1.22107 9.63649 1.22444 9.62298C1.26473 9.46187 1.33702 9.17276 1.19863 8.93472C1.10131 8.77914 0.953611 8.66161 0.780145 8.60173C0.682608 8.5723 0.581892 8.55467 0.480156 8.54924C0.460297 8.54402 0.438837 8.53918 0.416454 8.53413C0.303587 8.50866 0.167269 8.47789 0.0946688 8.36774L0.0676697 8.32574C0.0381072 8.28103 0.0176437 8.23094 0.00744867 8.17832C-0.00274639 8.1257 -0.00247342 8.07159 0.0082523 8.01907C0.018978 7.96656 0.0399461 7.91667 0.0699584 7.87226C0.0999708 7.82786 0.138439 7.7898 0.183166 7.76026Z"
    />
  </g>
);
